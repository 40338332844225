<script setup lang="ts">
import useBrandSettings from '../composables/useBrandSettings';

const { logo } = useBrandSettings();
</script>

<template>
  <div :class="$style.layout">
    <header :class="$style.header">
      <component :is="logo.component" :class="$style.logo" />
    </header>

    <main :class="$style.main">
      <slot />
    </main>
  </div>
</template>

<!-- eslint-disable vue/enforce-style-attribute -->
<style module>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.logo {
  height: 32px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 80px;
  padding: 24px 32px;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  min-height: 0;
  padding: 10% 32px 32px;
}
</style>
